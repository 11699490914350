import { getTokenFromStorage } from "./authentication";
import { store } from "../store/store";

// set header
export function header() {
  return {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'x-api-version': '5.6',
  };
}

// set header with token
export function authHeader(token?: string) {
  let authToken = getTokenFromStorage();
  return {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'x-api-version': '5.6',
    'Authorization': 'Bearer ' + authToken,
  };
}

// set header for get engima file upload url
export function enigmaHeader(){
  let authToken = getTokenFromStorage();
  return {
    'Accept-Encoding': 'gzip, deflate, br',
    'Accept': '*/*',
    'Connection' : 'keep-alive',
    'Authorization': 'Bearer ' + authToken,
  };
}

// set header for ranger - reedem pass
export function reedemHeader(){
  let rangerToken = localStorage.getItem("RangerToken") != null ?JSON.parse(localStorage.getItem("RangerToken") || "") : null;
  return {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'x-api-version': '5.6',
    'Authorization': 'Bearer ' + rangerToken,
  };
}

export function reportHeader(){
  return {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'x-api-version': '5.6',
    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMDQ3OCIsInVzZXJuYW1lIjoidnN1dGhhckB1c2VkaXJlY3QuY29tIiwicm9sZSI6IllvZGVsQWRtaW4iLCJuYmYiOjE3MDc5OTI4NjksImV4cCI6MTczOTYxNTI2MCwiaWF0IjoxNzA3OTkyODY5fQ.LhhDwPZPd2nX-Xr0cd5uTYz10c4qUGesImTMqnvjHbQ', //dev
    // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMDU5MyIsInVzZXJuYW1lIjoidnN1dGhhckB5b2RlbHBhc3MuY29tIiwicm9sZSI6IllvZGVsQWRtaW4iLCJuYmYiOjE3MTAxNDAwMDAsImV4cCI6MTc0MTY3NjAwMCwiaWF0IjoxNzEwMTQwMDAwfQ.pycOQdaCgbB8cb5Xp25YJh95lruGRxor6Zkup_4AcXY', //prod
  };
}

export function captchaAuthHeader(){
  let authToken = getTokenFromStorage();
  let token = store.getState().global.reCaptchaToken;
  return {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'x-api-version': '5.5',
    'CaptchaToken' : token,
    'Authorization': 'Bearer ' + authToken,
  };
}