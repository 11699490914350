import "css/global.scss";
import React, { useEffect, useState, lazy, Suspense, useCallback } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { globalActions } from 'actions/global/global.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from "redux";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
//services
import { globalServices } from 'services/global/global.services';
import { passesServices } from "services/passes/passes.services";
//constants
import { actionTypes } from 'constants/global/action.type';
import { passesConstants } from "constants/passes/passes.constant";
import Yloader from "controls/Yloader";
import { passesActions } from "actions/passes/passes.actions";
import YapiErroModal from "compositeControls/YapiErrorModal";
import { loginServices } from "services/login/login.services";
const RouteComponent = lazy(() => import('pages/Route'));

const setLinkInIndexHtmlPage = (enterpriseName, dynamicStyle) => {
  var linkElement = document.getElementById("dynamicLink");
  linkElement?.remove();
  var createLinkTag = document.createElement("link");
  createLinkTag.id = "dynamicLink";
  createLinkTag.type = "text/css";
  createLinkTag.rel = "stylesheet";
  createLinkTag.href = dynamicStyle;
  document.getElementsByTagName("head")[0].appendChild(createLinkTag);
  document.body.className = "";
  document.body.className = enterpriseName.toLowerCase();
}

// set dynamic script for weglot
const setDynamicScriptForWeglot = (baseName) => {
  let scriptElement = document.getElementById("dynamicWeglotScript");
  scriptElement?.remove();
  let weglotScriptElement = document.getElementById("dynamicWeglotConfiguration");
  weglotScriptElement?.remove();
  let weglotContainer: any = document.getElementsByClassName("weglot-container")[0];
  if (weglotContainer) {
    if (baseName.toLowerCase().includes("massdcr")) {
      weglotContainer.removeAttribute('style');
    } else {
      weglotContainer.style.display = "none";
      var clickEvent = document.getElementById("weglot-language-en");
      clickEvent?.click();
    }
    return;
  }

  if (baseName.toLowerCase().includes("massdcr")) {
    var createSrcElement = document.createElement('script');
    createSrcElement.id = "dynamicWeglotScript";
    createSrcElement.type = "text/javascript";
    createSrcElement.src = "https://cdn.weglot.com/weglot.min.js";
    var weglotElement = document.createElement('script');
    weglotElement.id = "dynamicWeglotConfiguration";
    weglotElement.innerHTML = "Weglot.initialize({ api_key: 'wg_46e36a21835d06a70e10b4c36d6da7039'});";
    let body = document.getElementsByTagName("body")[0];
    setTimeout(() => {
      body.insertBefore(createSrcElement, body.firstChild);
    }, 0);
    setTimeout(() => {
      body.insertBefore(weglotElement, body.firstChild);
      // body.insertBefore(createSrcElement, body.firstChild);
    }, 1000);
  }
}

const ScrollToTopOnRouteChange = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setDynamicScriptForWeglot(window.location.pathname.split("/")[1])
    }, 0)

    let scrollElement = document.getElementsByClassName("page-content")[0];
    scrollElement.id = "mainDivScroll"
    scrollElement?.scrollTo(0, 0);
  }, [window.location.pathname]);

  return null;
};

function App() {
  const [baseName, setBaseName] = useState("");
  const [dynamicTextJsonData, setDynamicTextJsonData] = useState(null);
  const dispatch: Dispatch<any> = useDispatch();
  const state = useSelector((state: any) => state.global);
  const baseNameFromStore = state.baseName;
  const  isRefreshReCaptcha = state.isRefreshReCaptcha
  const [ isShowRoute, setIsShowRoute ] = useState(false);
  const [ generalSettingData, setGeneralSettingData] = useState<any>(null);

  // set basename based on url
  useEffect(() => {
    // listen window back event
    window.onpopstate = function (event) {
      let isCallFromRannger = localStorage.getItem("IsCallFromRanger");
      let mobileCashierUrl = JSON.parse(localStorage.getItem("MobileCashierUrl") as any);
      if (isCallFromRannger === "true") {
        window.location.href = mobileCashierUrl;
      }
      else {
        let baseName = window.location.pathname.toLowerCase().split("/")[1] === "" ? "/" : "/" + window.location.pathname.split("/")[1]
        if (baseName.includes("wallet") || baseName.includes("profile") || baseName.includes("home") || baseName.includes("login")
          || baseName.includes("ExpressOrder") || baseName.includes("cart") || baseName.includes("navigationerror") || baseName.includes("walletsummary") || baseName.includes("valet")) {
          baseName = "/"
          // setTimeout(() => {
          //   setBaseName("/");
          // }, 0);
        }
        else {
          // setTimeout(() => {
          //   setBaseName(baseName);
          // }, 0);
        }
        dispatch({ type: actionTypes.SET_BASENAME, baseName: baseName })
      }

      // let baseName = window.location.pathname.toLowerCase().split("/")[1] === "" ? "/" : "/" + window.location.pathname.split("/")[1]
      // if (baseName.includes("wallet") || baseName.includes("profile") || baseName.includes("home") || baseName.includes("login")
      //   || baseName.includes("ExpressOrder") || baseName.includes("cart") || baseName.includes("navigationerror") || baseName.includes("walletsummary")) {
      //   baseName = "/"
      //   // setTimeout(() => {
      //   //   setBaseName("/");
      //   // }, 0);
      // }
      // else {
      //   // setTimeout(() => {
      //   //   setBaseName(baseName);
      //   // }, 0);
      // }
      // dispatch({ type: actionTypes.SET_BASENAME, baseName: baseName })
      //dispatch({ type: actionTypes.IS_CALL_FROM_BROWSER_BACK_EVENT, isCallFromBrowserBackBtn : true})
    };
    let baseName = window.location.pathname.toLowerCase().split("/")[1] === "" ? "/" : "/" + window.location.pathname.toLowerCase().split("/")[1]
    if (baseName.includes("wallet") || baseName.includes("profile") || baseName.includes("home") || baseName.includes("login")
      || baseName.includes("expressOrder") || baseName.includes("transfer") || baseName.includes("cancelpass") || baseName.includes("walletsummary") || baseName.includes("externalbridge") || baseName.includes("valet") 
      || baseName.includes("accessdenied")) {
      baseName = "/"
      // setTimeout(() => {
      //   setBaseName("/");
      // }, 0);
    }
    else {
      // setTimeout(() => {
      //   setBaseName(baseName);
      // }, 0);
    }
    dispatch({ type: actionTypes.SET_BASENAME, baseName: baseName })
  }, []);

  // set dynamic style, css when get response from api page-builder
  useEffect(() => {
    if (state?.fetchCustomMessageJSONData) {
      setLinkInIndexHtmlPage(window.location.pathname.split("/")[1], state?.fetchCustomMessageJSONData?.defaultStyle);
    }
  }, [state?.fetchCustomMessageJSONData])

  // api call for get dynamic style, theme, text data
  const fetchDynamicTextJsonData = async (enterpriseName) => {
    let textJsonData = await globalServices.fetchTextJsonData(enterpriseName);
    setDynamicTextJsonData(textJsonData);
    dispatch(globalActions.fetchCustomMessageJSONData(textJsonData));
    //dispatch({type:actionTypes.SET_BASENAME, baseName : baseName})
  }

  // from any page basename change call this useeffect
  useEffect(() => {
    if (baseNameFromStore) {
      (window as any)?.UpdateDynamicChatBot(baseNameFromStore.split("/")[1]);
      if (!baseNameFromStore.toLowerCase().includes("massdcr")) {
        let element: any = document.getElementsByClassName("weglot-container")[0];
        var clickEvent = document.getElementById("weglot-language-en")
        clickEvent?.click();
        if (element) {
          element.style.display = "none";
        }
      }
      setBaseName("");
      getGeneralSettingAndSetBaseName()
      getUserDetails();
      checkOfflineDetailExist();
    }
  }, [baseNameFromStore])

  const getUserDetails = async () => {
    let isUserReloaded = localStorage.getItem("IsUserReloaded2");
    const token = localStorage.getItem("BearerToken") != null ? JSON.parse(localStorage.getItem("BearerToken") || "") : null;
    if((isUserReloaded === null || isUserReloaded === "false") && token){
      let userData = await loginServices.getUserDetail();
      if (userData?.id) {
        localStorage.setItem("IsUserReloaded2", "true");
        localStorage.removeItem("IsUserReloaded");
        localStorage.removeItem("userDetails");
        localStorage.setItem("userDetails", JSON.stringify(userData));
        setIsShowRoute(true);
      }
      else if (userData?.status === 404) {
        localStorage.setItem("IsUserReloaded2", "true");
        localStorage.removeItem("IsUserReloaded");
        localStorage.removeItem("userDetails");
        localStorage.removeItem("BearerToken");
        setIsShowRoute(true);
      }
    }
    else{
      setIsShowRoute(true);
    }
  }

  const checkOfflineDetailExist = () => {
    const offlineUserDetail = localStorage.getItem("OfflineUserDetail") !== null ? JSON.parse(localStorage.getItem("OfflineUserDetail") || "") : null;
    if(!offlineUserDetail){
      const userDetails = localStorage.getItem("userDetails") !== null ? JSON.parse(localStorage.getItem("userDetails") || "") : null;
      const token = localStorage.getItem("BearerToken") != null ?JSON.parse(localStorage.getItem("BearerToken") || "") : null;
      if(userDetails && token){
        let tempData = {
          "userDetail": userDetails,
          "token": token
        }
        localStorage.setItem("OfflineUserDetail", JSON.stringify(tempData));
      }
    }
  }

  // from here set basename and get general setting
  //solving issue for click on scope name throw error basename mismtach (17-3-2023)
  const getGeneralSettingAndSetBaseName = async () => {
    dispatch(globalActions.fetchCustomMessageJSONData(null));
    let generalData = await passesServices.getGeneralSetting();
    if (generalData?.status === 200) {
      setGeneralSettingData(generalData?.data);
      setBaseName(baseNameFromStore);
      dispatch({ type: passesConstants.GET_GENERAL_SETTINGS, settingDetail: generalData })
      dispatch({ type: actionTypes.IS_CALL_FROM_BROWSER_BACK_EVENT, isCallFromBrowserBackBtn: false })
    }
    else {
      setBaseName("/")
    }
    fetchDynamicTextJsonData(baseNameFromStore.split("/")[1])
  }

    // on captcha verify set state variable 
    const onCaptchaVerify = useCallback((CaptchaToken: string) => {
      dispatch({ type: actionTypes.SET_RECAPTCHA_TOKEN, reCaptchaToken: CaptchaToken });
      let captchaElement = document.querySelector(".grecaptcha-badge") as any
      if (captchaElement) {
        captchaElement.style.zIndex = "11111";
        captchaElement.style.bottom = "100px";
      }
    }, []);

  return (
    <Suspense fallback={<Yloader />}>
            {isShowRoute ?
              <Router basename={baseName}>
                {generalSettingData?.EnableCaptcha?.toLowerCase() === "true" ?
                  <GoogleReCaptchaProvider reCaptchaKey={generalSettingData?.CaptchaClientKey}>
                    <YapiErroModal />
                    <ScrollToTopOnRouteChange />
                    <RouteComponent />
                    <GoogleReCaptcha onVerify={onCaptchaVerify} />
                  </GoogleReCaptchaProvider>
                  :
                  <>
                    <YapiErroModal />
                    <ScrollToTopOnRouteChange />
                    <RouteComponent />
                  </>
                }
              </Router>
              : <Yloader />
            }
    </Suspense>
  );
}

export default App;
